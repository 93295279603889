<template>
  <LayoutHome :key="$route.path">
    <div style="height: 270px; padding: 94px 0 0 0" class="banner" :style="{backgroundImage: 'url(' + bannerInfo.backgroundAttachmentUrl + ')'}">
      <h3 class="main-title">{{ bannerInfo.name }}</h3>
      <h4 class="sub-title">{{ bannerInfo.description }}</h4>
    </div>
    <div class="course-wrapper">
      <div style="line-height: 20px">
        <span style="font-size: 14px; color: #2a2a2a; font-weight: 500">课程类别：</span>
        <span class="search-label" @click="getList({catalog:''})" :class="{active:currentType === ''}" :style="{color:currentType === ''?themeColor:'#676767'}">全部</span>
        <span
          class="search-label"
          :class="{active:currentType === key}"
           :style="{color:currentType === key? themeColor:'#676767'}"
          v-for="({ key, value }) in courseTypeList"
          :key="key" 
          @click="getList({catalog:key})">{{ value }}</span>
      </div>
      <div style="line-height: 20px; margin-top: 16px">
        <span style="font-size: 14px; color: #2a2a2a; font-weight: 500">认定级别：</span>
        <span class="search-label" @click="getList({label:''})"  :class="{active:currentLabel === ''}" :style="{color:currentLabel === ''? themeColor:'#676767'}">全部</span>
        <span
          v-for="{ id, name } in levelList"
          :key="id"
          class="search-label"
          :class="{active:currentLabel === id}"
           :style="{color:currentLabel === id? themeColor:'#676767'}"
          @click="getList({label:id})">{{ name }}</span>
      </div>
      <div style="line-height: 20px; margin-top: 16px">
        <span style="font-size: 14px; color: #2a2a2a; font-weight: 500">课程年份：</span>
        <span class="search-label" :class="{active:currentYear === ''}"  @click="getList({year:''})" :style="{color:currentYear === ''? themeColor:'#676767'}">全部</span>
        <span class="search-label" :class="{active:currentYear === year}" @click="getList({year})" v-for="year in yearList" :key="year" :style="{color:currentYear === year? themeColor:'#676767'}">{{year}}</span>
        <el-input placeholder="请输入课程名称" v-model="courseName" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" :style="{color:themeColor}" @click="getList()"></el-button>
        </el-input>
      <div style="clear:both"></div>
      </div>
      <el-row :gutter="8">
        <el-col v-for="item in courseList" :key="item.id" :span="6">
          <el-card shadow="never"  class="course-card" :body-style="{ padding: '0px' }" style="margin-top: 20px">
            <div
              style="cursor: pointer"
              @click="toDetail(item.id, item.catalog)"
            >
            <span v-if="item.labelDescription && item.labelDescription === '国家级'" class="label-description">{{item.labelDescription}}</span>
              <img :src="item.coverAttachmentUrl || `/images/cover/${item.catalog}.png`" class="image" />
              <div style="padding: 14px">
                <el-tooltip effect="dark" :content="item.courseName" placement="top-start">
                  <span style="font-size: 16px; font-weight: 600; color: #2a2a2a">{{ item.courseName || "**" }}</span>
                </el-tooltip>
                <div class="bottom clearfix">
                  <el-tooltip effect="dark" :content="(item.introduce || '').replace(/<\/?[^>]*>/g, '')" placement="top-start">
                    <div class="time" style="font-weight: 400;color: #999;font-size: 14px;line-height: 20px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;min-height: 20px;">
                      {{ (item.introduce || "").replace(/<\/?[^>]*>/g, "") }}
                    </div>
                  </el-tooltip>
                  <div style="color: #999; font-size: 12px; margin-top: 40px">
                    <span :style="{color:themeColor}">{{ item.hours || 0 }}</span>
                    <span style="">学时</span>
                    <span :style="{color:themeColor}">{{ item.credit || 0 }}</span>
                    <span style="">学分</span>
                    <span style="float: right">
                      <span style="">已选学生</span>
                      <span :style="{color:themeColor}">{{studiesDesc(item.studies)}}</span>
                      <span style="">人</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <div class="page">
        <span
          @click="handlePageChange(currentPage - 1)"
          class="prev"
          :class="{ disable: 1 === currentPage }">上一页</span>
        <span
          @click="handlePageChange(i)"
          class="num"
          :class="{ active: i === currentPage }"
          :style="{color:i === currentPage ? '#fff' : '#666',backgroundColor:i=== currentPage ? themeColor : '#fff'}"
          v-for="i in totalPages"
          :key="i">{{ i }}</span>
        <span
          @click="handlePageChange(currentPage + 1)"
          class="next"
          :class="{ disable: currentPage === totalPages }">下一页</span>
      </div>
    </div>
  </LayoutHome>
</template>
<script>
import LayoutHome from "./../../../components/Layout";
import { getCookie,setCookie,removeCookie,getLoginUUId,getLoginUserId,getLoginRealName,getLoginUserName,getLoginUserUrl } from "../../../plugins/cookie";
export default {
  components: {
    LayoutHome,
  },
  created() {
    this.initBannerInfo();
    this.initCourseTypeList();
    this.initCourseLabelList();
    this.getList();
    this.getLevel();
    this.getYearList();
  },
  data() {
    return {
      courseList: [],
      courseTypeList: [],
      courseLabelList: [],
      yearList:[],
      totalPages: 1,
      currentPage: 1,
      currentType:'',
      currentLabel:'',
      currentYear:'',
      params:{},
      courseName:'',
      levelList:[],
      bannerInfo: {
        name: '',
        descripton: '',
        backgroundAttachmentUrl: '',
      },
    };
  },
  methods: {
    async getSchoolEntity() {
      let school = await this.$get(`/admin/getSchoolByContext/${this.$route.params.context}`);
      return school;
    },
    studiesDesc(studies) {
      var regPos = /^\d+(\.\d+)?$/; //非负浮点数
      if (!regPos.test(studies)) {
        return 0;
      }
      if (studies < 10000) {
        return studies;
      }
      return (parseFloat(studies) / 10000).toFixed(1) + "万";
    },
    async initBannerInfo() {
      switch (this.$route.meta.origin) {
        case "undergraduate":{
            const result1 = await this.$get("/school-undergraduate/findList/foreign", {foreign:"schoolId", value:(await this.getSchoolEntity()).id});
            this.bannerInfo = result1[0] || {};
          }
          break;
        case "declaration":{
            const result2 = await this.$get("/school-declaration/findList/foreign", {foreign:"schoolId", value:(await this.getSchoolEntity()).id});
            this.bannerInfo = result2[0] || {};
          }
          break;
        case "others1":{
            const result3 = await this.$get("/school-others1/findList/foreign", {foreign:"schoolId", value:(await this.getSchoolEntity()).id});
            this.bannerInfo = result3[0] || {};
          }
          break;
        case "others2":{
            const result4 = await this.$get("/school-others2/findList/foreign", {foreign:"schoolId", value:(await this.getSchoolEntity()).id});
            this.bannerInfo = result4[0] || {};
          }
          break;
        case "others3":{
            const result3 = await this.$get("/school-others3/findList/foreign", {foreign:"schoolId", value:(await this.getSchoolEntity()).id});
            this.bannerInfo = result3[0] || {};
          }
          break;
        case "others4":{
            const result3 = await this.$get("/school-others4/findList/foreign", {foreign:"schoolId", value:(await this.getSchoolEntity()).id});
            this.bannerInfo = result3[0] || {};
          }
          break;
        case "others5":{
            const result3 = await this.$get("/school-others5/findList/foreign", {foreign:"schoolId", value:(await this.getSchoolEntity()).id});
            this.bannerInfo = result3[0] || {};
          }
          break;
        case "others6":{
            const result3 = await this.$get("/school-others6/findList/foreign", {foreign:"schoolId", value:(await this.getSchoolEntity()).id});
            this.bannerInfo = result3[0] || {};
          }
          break;
        case "others7":{
            const result3 = await this.$get("/school-others7/findList/foreign", {foreign:"schoolId", value:(await this.getSchoolEntity()).id});
            this.bannerInfo = result3[0] || {};
          }
          break;
        case "others8":{
            const result3 = await this.$get("/school-others8/findList/foreign", {foreign:"schoolId", value:(await this.getSchoolEntity()).id});
            this.bannerInfo = result3[0] || {};
          }
          break;
        default:
          return "";
      }
    },
    async getYearList(){
      switch (this.$route.meta.origin) {
        case "undergraduate":{
            const result1 = await this.$get("/admin/getUndergraduateCourseYear");
            this.yearList = result1;
          }
          break;
        case "declaration":{
            const result2 = await this.$get("/admin/getDeclarationCourseYear");
            this.yearList = result2;
          }
          break;
        case "others1":{
            const result3 = await this.$get("/admin/getOthers1CourseYear");
            this.yearList = result3;
          }
          break;
        case "others2":{
            const result4 = await this.$get("/admin/getOthers2CourseYear");
            this.yearList = result4;
          }
          break;
        case "others3":{
            const result3 = await this.$get("/admin/getOthers3CourseYear");
            this.yearList = result3;
          }
          break;
        case "others4":{
            const result3 = await this.$get("/admin/getOthers4CourseYear");
            this.yearList = result3;
          }
          break;
        case "others5":{
            const result3 = await this.$get("/admin/getOthers5CourseYear");
            this.yearList = result3;
          }
          break;
        case "others6":{
            const result3 = await this.$get("/admin/getOthers6CourseYear");
            this.yearList = result3;
          }
          break;
        case "others7":{
            const result3 = await this.$get("/admin/getOthers7CourseYear");
            this.yearList = result3;
          }
          break;
        case "others8":{
            const result3 = await this.$get("/admin/getOthers8CourseYear");
            this.yearList = result3;
          }
          break;
        default:
          return "";
      }
    },
    handlePageChange(pageNum) {
      if (pageNum < 1 || pageNum > this.totalPages) return;
      this.getList({ pageNum });
    },
    async getList(data = {}) {
      const params = {
        ...this.params,
        courseName:this.courseName,
        pageSize: 8,
        pageNum: 1,
        ...data,
      };
      const { content, totalElements, totalPages } = await this.$get(
        `/admin/get${this.toUpperCaseFirst(this.$route.meta.origin)}CourseDtoPage`,
        params
      );
      this.total = totalElements;
      this.courseList = content;
      this.totalPages = totalPages;
      this.currentPage = params.pageNum;
      this.currentType = params.catalog || '';
      this.currentLabel = params.label || '';
      this.currentYear = params.year || '';
      this.params = params;
    },
    async getLevel() {
      const levelList = await this.$get("/level/findPage", {
        pageSize: 100000,
        pageNum: 1,
        type:this.$route.meta.origin
      });
      this.levelList = levelList.content;
    },
    async initCourseTypeList() {
      let arr = [];
      let list = await this.$get("/enums/list", { type: "CourseTypeEnum" });
      let display = [];
      switch (this.$route.meta.origin) {
        case "undergraduate":{
            let res1 = await this.$get("/school-undergraduate/findList/foreign",{foreign:'schoolId',value:(await this.getSchoolEntity()).id});
            display = res1[0].display ? res1[0].display.split(',') : [];
          }
          break;
        case "declaration":{
            let res2 = await this.$get("/school-declaration/findList/foreign",{foreign:'schoolId',value:(await this.getSchoolEntity()).id});
            display = res2[0].display ? res2[0].display.split(',') : [];
          }
          break;
        case "others1":{
            let res3 = await this.$get("/school-others1/findList/foreign",{foreign:'schoolId',value:(await this.getSchoolEntity()).id});
            display = res3[0].display ? res3[0].display.split(',') : [];
          }
          break;
        case "others2":{
            let res4 = await this.$get("/school-others2/findList/foreign",{foreign:'schoolId',value:(await this.getSchoolEntity()).id});
            display = res4[0].display ? res4[0].display.split(',') : [];
          }
          break;
        case "others3":{
            let res3 = await this.$get("/school-others3/findList/foreign",{foreign:'schoolId',value:(await this.getSchoolEntity()).id});
            display = res3[0].display ? res3[0].display.split(',') : [];
          }
          break;
        case "others4":{
            let res3 = await this.$get("/school-others4/findList/foreign",{foreign:'schoolId',value:(await this.getSchoolEntity()).id});
            display = res3[0].display ? res3[0].display.split(',') : [];
          }
          break;
        case "others5":{
            let res3 = await this.$get("/school-others5/findList/foreign",{foreign:'schoolId',value:(await this.getSchoolEntity()).id});
            display = res3[0].display ? res3[0].display.split(',') : [];
          }
          break;
        case "others6":{
            let res3 = await this.$get("/school-others6/findList/foreign",{foreign:'schoolId',value:(await this.getSchoolEntity()).id});
            display = res3[0].display ? res3[0].display.split(',') : [];
          }
          break;
        case "others7":{
            let res3 = await this.$get("/school-others7/findList/foreign",{foreign:'schoolId',value:(await this.getSchoolEntity()).id});
            display = res3[0].display ? res3[0].display.split(',') : [];
          }
          break;
        case "others8":{
            let res3 = await this.$get("/school-others8/findList/foreign",{foreign:'schoolId',value:(await this.getSchoolEntity()).id});
            display = res3[0].display ? res3[0].display.split(',') : [];
          }
          break;
        default:
          break;
      }
      display.forEach(element => {
        list.forEach(ele => {
          if (element === ele.key) {
            arr.push(ele);
          }
        });
      });
      this.courseTypeList = arr;
    },
    async initCourseLabelList() {
      let list = await this.$get("/enums/list", { type: "CourseMixtureLabelEnum" });
      this.courseLabelList = list;
    },
    toUpperCaseFirst(str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
    },
    toDetail(id, catalog) {
      this.$router.push(`/${this.$route.params.context}/course-view/${id}/${catalog}`);
    },
    getDisplay(idx) {
      let ret = {};
      this.showModules.forEach(ele => {
        this.$store.state.tabs.forEach(element => {
          if (element.menuId === ele.menuId && element.menuId === idx) {
            let e = element;
            e.name = ele.menuName;
            if (!e.path.startsWith("/" + this.$route.params.context)) {
              e.path = "/" + this.$route.params.context + e.path;
            }
            ret = e;
          }
        });
      });
      return ret;
    },
  },
  computed: {
    themeColor(){
      return this.$store.state.themeColor;
    },
    origin() {
      return this.$route.meta.origin;
    },
  },
};
</script>
<style lang="scss" scoped>
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}
.banner {
  background-repeat: no-repeat;
  background-position: center bottom;
  height:270px;
  padding:94px 0 0;
}
.disable {
  cursor: not-allowed;
}
.disable:hover {
  color: #aaa;
}
.search-label {
  font-size: 14px;
  font-weight: 500;
  color: #676767;
  margin-right: 16px;
  cursor: pointer;
}
.search-label.active,
.search-label:hover {
  color: #5676dc;
}
.course-wrapper {
  width: 1200px;
  margin: 0 auto;
  border: solid 1px #eee;
  padding: 20px 28px;
  position: relative;
  top: -52px;
  background: #fff;
  border-radius: 8px;
}
.main-title {
  font-size: 32px;
  font-weight: normal;
  color: #fff;
  line-height: 46px;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 0 5px 0;
}
.sub-title {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 29px;
  text-align: center;
  margin: 0;
}
.course-wrapper .image {
  width: 100%;
  height: 150px;
  display: block;
}
.course-card{
  position: relative;
  border-color: #eee;
  &:hover{
    border-color: #5676dc;
  }
  .label-description{
    background-color: rgb(211,100,78); 
    background-image: linear-gradient(to right, rgb(211,100,78),rgb(232 ,138 ,108));
    position:absolute;
    top: 6px;
    right: 6px;
    font-size: 12px;
    padding: 4px 12px;
    color: #fff;
    border-radius: 4px;
  }
}
.input-with-select{
  float: right;
  width: 200px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #E9EFF6;
}
  .input-with-select  /deep/ .el-input__inner,
    .input-with-select  /deep/ .el-input-group__append,
    .input-with-select  /deep/ .el-input-group__append .el-button{
      border: 0;
      background-color: transparent;
    }
    .input-with-select  /deep/ .el-icon-search{
      // color: #5676dc;
    }
</style>
<style>
.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}
</style>